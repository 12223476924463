import { graphql, useStaticQuery } from 'gatsby'
import { INewsItem } from '../types'
import { AppRegionEnum } from '../types/app-region.enum'

export const useBlogData = (region: AppRegionEnum): INewsItem[] => {
  const images = useStaticQuery(imagesQuery)
  const news: Record<AppRegionEnum, INewsItem[]> = {
    [AppRegionEnum.Ru]: [
      {
        id: '__6-PRICHIN-ISPOLSOVAT-INSPECTION__',
        title:
          '6 причин цифровизации строительного контроля с SIGNAL INSPECTION',
        to: '/blog/6-prichin-ispolsovat-inspection/',
        image:
          images.prichiny_ispolsovat_inspection.childImageSharp.gatsbyImageData,
      },
    ],
    [AppRegionEnum.Us]: [],
  }

  return news[region]
}

const imagesQuery = graphql`
  query {
    prichiny_ispolsovat_inspection: file(
      relativePath: { eq: "blog/6-prichin-ispolsovat-inspection/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
  }
`
