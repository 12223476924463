import React from 'react'
import { useTranslations } from '../hooks/use-translations'
import SEO from '../components/seo'
import News from '../components/News'
import { GlobalContext } from '../context/GlobalContext'
import { AppRegionEnum } from '../types/app-region.enum'
import { useBlogData } from '../hooks/useBlogData'

const BlogPage: React.FC = () => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  const news = useBlogData(region as AppRegionEnum)
  const title = t('Blog')

  return (
    <>
      <SEO
        title={region === AppRegionEnum.Ru ? t('seo_title_blog') : title}
        description={
          region === AppRegionEnum.Ru ? t('seo_description_blog') : ''
        }
      />
      <News title={title} items={news} />
    </>
  )
}

export default BlogPage
